/** @jsxImportSource @emotion/react */
import React, {FC} from "react";
import {PSRightOverDrawer, Text} from "../../ui-kit";
import {AuditLog} from "../../gql/generated/graphql";
import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import {AuditLogsDrawerStyle} from './AuditLogsDrawer.css'
import {KeyValueRow} from "../../ui-kit";

interface Detail {
    key: keyof AuditLog,
    label: string;
}

const details: Detail[] = [
    {
        key: 'time',
        label: 'Time',
    },
    {
        key: 'user',
        label: 'User',
    },
    {
        key: 'ip',
        label: 'IP',
    },
    {
        key: 'actionType',
        label: 'Action Type',
    },
    {
        key: 'auditAction',
        label: 'Action',
    },
    {
        key: 'resource',
        label: 'Resource',
    },
    {
        key: 'logId',
        label: 'Log Id',
    },
]


interface IProps {
    rowData: AuditLog;
    onClose: () => void;
}

const AuditLogsDrawer: FC<IProps> = (props) => {
    const {rowData, onClose} = props;

    const { policyChanges } = rowData;

    return (
        <PSRightOverDrawer
            isOpen
            onClose={onClose}
        >
            <div css={AuditLogsDrawerStyle.self}>
                <Text variant='header3' css={AuditLogsDrawerStyle.header}>Details</Text>
                <div>
                    {details.map(({key, label}) => {
                        return (
                            <KeyValueRow
                                label={label}
                                value={rowData[key]}
                            />
                        )
                    })}
                </div>
                {policyChanges &&
                    <>
                        <Text textCss={AuditLogsDrawerStyle.textKey} variant='monoSmall'>Change details</Text>
                        <CodeMirror value={JSON.stringify(policyChanges, null, 4)} readOnly theme={'dark'} extensions={[langs.json()]}/>
                    </>
                }
            </div>
        </PSRightOverDrawer>
    )
}

export default AuditLogsDrawer;