/** @jsxImportSource @emotion/react */
import React, {useState} from "react";
import {TextField, TextFieldProps} from '@mui/material';
import {useDebounce} from "react-use";
import EndAdornment from './EndAdornment';
import {PSInputStyles} from './PSInput.css'
import {IconNames} from "../../Icon/Icon";
import {SerializedStyles} from "@emotion/react";

type BaseProps = {
    value: string;
    inputLabel: string;
    onChange: (val: string | null) => void;
    isLoading: boolean;
    inputCss?: SerializedStyles;
    includeClearButton?: boolean;
    endAdornmentClassName?: string;
} & TextFieldProps<'outlined'>

type ConditionalDebounceProps = {
    isDebounce?: false;
    debounceTimeout?: number;
} | {
    isDebounce: true;
    debounceTimeout: number;
}

type ConditionalIconProps = {
    includeRightIcon: true;
    rightIconName: IconNames ;
} | {
    includeRightIcon?: false;
    rightIconName?: IconNames
}

type IProps = BaseProps & ConditionalDebounceProps & ConditionalIconProps;

const PSInput: React.FC<IProps> = (props) => {
    const {
        onChange,
        inputLabel,
        inputCss,
        isLoading,
        debounceTimeout,
        endAdornmentClassName,
        isDebounce = false,
        includeRightIcon = false,
        includeClearButton = true,
        size = 'small',
        value = '',
        rightIconName = '',
        ...textFieldProps
    } = props;

    const [debouncedValue, setDebouncedValue] = useState<string>(value);

    const [, cancel] = useDebounce(() => {
        if(!isDebounce) return;
        if (debouncedValue?.trim()) {
            onChange(debouncedValue);
        } else {
            onChange('');
        }
        return () => cancel();
    }, debounceTimeout, [debouncedValue]);

    const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.target.value;
        if (isDebounce) return setDebouncedValue(targetValue)
        onChange(targetValue);
    }

    const onClearClick = () => {
        setDebouncedValue('')
        onChange('')
    }

    return (
        <TextField
            {...textFieldProps}
            size={size}
            value={isDebounce ? debouncedValue : value}
            onChange={localOnChange}
            placeholder={inputLabel}
            label={inputLabel}
            css={[PSInputStyles.self, inputCss]}
            InputProps={{
                endAdornment:(<EndAdornment
                    isLoading={isLoading}
                    showClear={includeClearButton && Boolean(debouncedValue)}
                    onClick={onClearClick}
                    className={endAdornmentClassName}
                    includeRightIcon={includeRightIcon}
                    iconName={rightIconName}
                />)
            }}
        />

    )
}

export default PSInput;
