/** @jsxImportSource @emotion/react */
import React from "react";
import {CircularProgress, IconButton} from "@mui/material";
import {Icon} from "../../index";
import {EndAdornmentStyle} from './EndAdornment.css'
import {IconNames} from "../../Icon/Icon";

interface EndAdornmentProps {
    isLoading: boolean;
    showClear: boolean;
    includeRightIcon: boolean;
    onClick: () => void;
    className: string | undefined;
    iconName: IconNames;
}

const EndAdornment: React.FC<EndAdornmentProps> = (props) => {
    const {
        isLoading,
        showClear,
        onClick,
        includeRightIcon,
        iconName,
        className = ''
    } = props;

    return (
        <div css={EndAdornmentStyle.self}>
            {showClear &&
                <IconButton
                    size="small"
                    onClick={onClick}
                    className='clearIcon'
                    css={EndAdornmentStyle.clearButton}
                >
                    <Icon iconName='PSRemoveIcon'/>
                </IconButton>
            }
            {isLoading ?
                <CircularProgress color="inherit" size={20} />
                :
                (includeRightIcon && <Icon iconName={iconName} iconSize={20} className={`${className} _endAdornmentIcon`}/>)}
        </div>
    )
}

export default EndAdornment;