/** @jsxImportSource @emotion/react */
import React, {FC, memo, useEffect, useMemo, useState} from "react";
import {Log, Pagination, SortByFields, SortDirection, AuditLog} from "../../gql/generated/graphql";
import {useDateContext} from "../../contexts";
import {toast} from "react-toastify";
import {dateStringISOToFormatted} from "../../utils";
import {
    PSIconButton,
    SortHeader,
    Grid,
    sortDirectionEnumToStringMap,
} from "../../ui-kit";
import {AgGridReact, CustomCellRendererProps} from "ag-grid-react";
import {
    ColDef,
    SelectionChangedEvent
} from 'ag-grid-community';

import {css} from "@emotion/react";

type IGridProps = {
    rowsData: Array<Partial<AuditLog>> | undefined;
    pagination: Pagination | undefined;
    currentPage: number;
    sortDirection: SortDirection;
    sortBy: SortByFields;
    itemsPerPage: number;
    rowIndex: number | null;
    isLoading?: boolean;
    isError?: boolean;
    refetchTableData: () => void;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
    setSortBy: React.Dispatch<React.SetStateAction<SortByFields>>;
    setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
    setRowIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const AuditGrid: FC<IGridProps> = (props) => {
    const {
        rowsData,
        pagination,
        isLoading,
        isError,
        rowIndex,
        currentPage,
        itemsPerPage,
        sortBy,
        sortDirection,
        setCurrentPage,
        setItemsPerPage,
        setSortBy,
        setSortDirection,
        setRowIndex,
    } = props;

    const { date } = useDateContext();

    const copyLinkToClipboard = (id: string) => {
        const dateParsed = date.dates.map(x => x.toISOString()).join(',');
        const pageUrl = `${window.location.origin}/audit?id=${id}&dateType=absolute&dates=${encodeURIComponent(dateParsed)}`;
        navigator.clipboard.writeText(pageUrl);
        toast.success('Link copied to clipboard!', {
            hideProgressBar: true,
            autoClose: 2000
        });
    }

    const colDefs: Array<ColDef> = useMemo(() => ([
            {
                field: "time",
                headerName: "TIME",
                valueFormatter: (params) => dateStringISOToFormatted(params.value),
                colSpan: params => params.data?.isError ? 99 : 1,
                flex: 1,
                minWidth: 240,
                maxWidth: 240,
                enumFieldSort: SortByFields.Time,
                headerComponent: SortHeader,
                headerComponentParams: {
                    headerName: 'TIME',
                    sortDirection: sortBy === SortByFields.Time ? sortDirectionEnumToStringMap[sortDirection] : undefined,
                    onClick: () => {
                        if (sortBy === SortByFields.Time) {
                            setSortDirection((prev) => {
                                if (prev === SortDirection.Ascending) return SortDirection.Descending;
                                return SortDirection.Ascending;
                            });
                        } else {
                            setSortBy(SortByFields.Time);
                            setSortDirection(SortDirection.Descending);
                        }
                    }
                }
            },
            {
                field: "user",
                headerName: "USER",
                minWidth: 170,
                flex: 1,
            },
            {
                field: "ip",
                headerName: "IP",
                maxWidth: 150,
            },
            {
                field: "actionType",
                headerName: "ACTION TYPE",
                maxWidth: 150,
            },
            {
                field: "auditAction",
                headerName: "ACTION",
                flex: 1,
            },
            {
                field: "resource",
                headerName: "RESOURCE",
                flex: 1,
            },
            {
                field: "actions",
                headerName: "",
                maxWidth: 112,
                sortable: false,
                resizable: false,
                pinned: 'right',
                cellRenderer: memo((params: CustomCellRendererProps & { showViewText: boolean }) => (
                    <div css={
                        css`
                    display: flex;
                    margin: auto !important;
                    gap: 12px;
                    `
                    }>
                        <PSIconButton variant='circle' variantType='secondary' onClick={() => setRowIndex(params.node.rowIndex)} iconName='PSInspectIcon' iconSize={20} tooltipLabel='Show details' />
                        <PSIconButton variant='circle' variantType='secondary' onClick={() => copyLinkToClipboard(params.data.id)} iconName='PSCopyIcon' iconSize={20} tooltipLabel='Copy audit log link' />
                    </div >
                )),
            },
        ]),
        [isLoading, rowIndex, sortBy, sortDirection]);

    const gridApiRef = React.useRef<AgGridReact>(null);

    const [selectedRows, setSelectedRows] = useState<Array<Log>>([]);

    const handleSelectionChanged = (event: SelectionChangedEvent<Log>) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map((node: any) => node.data);
        setSelectedRows(selectedData);
    }

    useEffect(() => {
        if (!gridApiRef.current) return;
        if (!isError) return;
        gridApiRef?.current?.api?.showNoRowsOverlay();
    }, [gridApiRef, isError])


    useEffect(() => {
        gridApiRef.current?.api?.sizeColumnsToFit();
    }, [isLoading, rowIndex, sortBy, sortDirection])


    return (
       <Grid
           colDefs={colDefs}
           handleSelectionChanged={handleSelectionChanged}
           SelectedRowIdx={rowIndex}
           isError={isError}
           data={rowsData}
           selectedRows={selectedRows}
           pagination={pagination}
           currentPage={currentPage}
           setCurrentPage={setCurrentPage}
           setItemsPerPage={setItemsPerPage}
           itemsPerPage={itemsPerPage}
           setSortBy={setSortBy}
           setSortDirection={setSortDirection}
           setRowIndex={setRowIndex}
           isLoading={isLoading}
       />
    )
}

export default AuditGrid;