import { css } from '@emotion/react';

export const AuditPageStyles = {
    filtersContainer: css`
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
    `,
    clearIdContainer: css`
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
    `
}