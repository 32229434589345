/** @jsxImportSource @emotion/react */
import React, {memo, useEffect, useMemo, useState} from "react";
import {Log, Pagination, SortByFields, SortDirection} from "../../gql/generated/graphql";
import {useDateContext} from "../../contexts";
import {toast} from "react-toastify";
import {dateStringISOToFormatted, extractEntityTypesToArray, createPlaceholderData} from "../../utils";
import { ColDef, SelectionChangedEvent } from 'ag-grid-community';
import {
    Chips,
    GenAiApplication,
    IsValid,
    LogStatus,
    PSDialog,
    PSIconButton,
    SortHeader,
    Text,
    TextType,
    Grid,
    sortDirectionEnumToStringMap,
} from "../../ui-kit";
import {AgGridReact, CustomCellRendererProps} from "ag-grid-react";
import {css} from "@emotion/react";
import FalseClassification, {TClassificationBody, TTextType} from "./FalseClassification";
import ViewHiddenText from "./ViewHiddenText";

type IGridProps = {
    rowsData: Array<Partial<Log>> | undefined,
    pagination: Pagination | undefined
    currentPage: number
    sortDirection: SortDirection
    sortBy: SortByFields
    rowIndex: number | null
    itemsPerPage: number
    isLoading?: boolean
    isError?: boolean
    isTextHidden?: boolean
    logsAlerts: "alerts" | "all"
    textCacheMap: Map<string, string>
    refetchTableData: () => void;
    setTextCacheMap: React.Dispatch<React.SetStateAction<Map<string, string>>>
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    setItemsPerPage: React.Dispatch<React.SetStateAction<number>>
    setSortBy: React.Dispatch<React.SetStateAction<SortByFields>>
    setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>
    setRowIndex: React.Dispatch<React.SetStateAction<number | null>>
    rowIndexViewTextRequestModal: number | null
    setRowIndexViewTextRequestModal: React.Dispatch<React.SetStateAction<number | null>>
}

const AlertsLogGrid: React.FC<IGridProps> = (props) => {

    const {
        rowsData,
        pagination,
        isLoading,
        isError,
        currentPage,
        itemsPerPage,
        rowIndex,
        sortBy,
        sortDirection,
        isTextHidden,
        logsAlerts,
        textCacheMap,
        rowIndexViewTextRequestModal,
        setRowIndexViewTextRequestModal,
        refetchTableData,
        setTextCacheMap,
        setCurrentPage,
        setItemsPerPage,
        setSortBy,
        setSortDirection,
        setRowIndex,
    } = props;

    const [rowData, setRowData] = useState<Array<Partial<Log>>>(createPlaceholderData(20));

    const [rowIndexTrueClassificationModal, setRowIndexTrueClassificationModal] = useState<number | null>(null);
    const isTrueClassificationModalOpen = rowIndexTrueClassificationModal !== null;

    const [rowIndexFalseClassificationModal, setRowIndexFalseClassificationModal] = useState<number | null>(null);
    const [isFalseClassificationFormValid, setIsFalseClassificationFormValid] = useState(false);
    const [submitFalseClassificationForm, setSubmitFalseClassificationForm] = useState(() => () => { });
    const isFalseClassificationModalOpen = rowIndexFalseClassificationModal !== null;

    const handleFalseClassificationFormStateChange = (isValid: boolean) => {
        setIsFalseClassificationFormValid(isValid);
    };

    const [isViewTextRequestFormValid, setIsViewTextRequestFormValid] = useState(false);
    const [submitViewTextRequestForm, setSubmitViewTextRequestForm] = useState<(...args: any) => string>(() => () => '');

    const isViewTextRequestModalOpen = rowIndexViewTextRequestModal !== null;

    const handleViewTextRequestFormStateChange = (isValid: boolean) => {
        setIsViewTextRequestFormValid(isValid);
    };

    const { date } = useDateContext();

    const copyLinkToClipboard = (activityId: string) => {
        const dateParsed = date.dates.map(x => x.toISOString()).join(',');
        const pageUrl = `${window.location.origin}/activity-monitor?activityId=${activityId}&dateType=absolute&dates=${encodeURIComponent(dateParsed)}`;
        navigator.clipboard.writeText(pageUrl);
        toast.success('Link copied to clipboard!', {
            hideProgressBar: true,
            autoClose: 2000
        });
    }

    const colDefs: Array<ColDef> = useMemo(() => ([
            { field: "select", headerName: "", checkboxSelection: !isLoading, headerCheckboxSelection: false, resizable: false, pinned: 'left', maxWidth: 65, hide: true },
            {
                field: "time",
                headerName: "TIME",
                valueFormatter: (params) => dateStringISOToFormatted(params.value),
                colSpan: params => params.data?.isError ? 99 : 1,
                flex: 1,
                minWidth: 240,
                maxWidth: 240,
                enumFieldSort: SortByFields.Time,
                headerComponent: SortHeader,
                headerComponentParams: {
                    headerName: 'TIME',
                    sortDirection: sortBy === SortByFields.Time ? sortDirectionEnumToStringMap[sortDirection] : undefined,
                    onClick: () => {
                        if (sortBy === SortByFields.Time) {
                            setSortDirection((prev) => {
                                if (prev === SortDirection.Ascending) return SortDirection.Descending;
                                return SortDirection.Ascending;
                            });
                        } else {
                            setSortBy(SortByFields.Time);
                            setSortDirection(SortDirection.Descending);
                        }
                    }
                }
            },
            {
                field: "isValid",
                headerName: "VERDICT",
                cellRenderer: memo((params: CustomCellRendererProps) =>
                    <div
                        css={
                            css`
                        margin: auto !important;
                        `
                        }>
                        <IsValid {...params} />
                    </div>
                ),
                maxWidth: 100
            },
            {
                field: "action",
                headerName: "ACTION",
                cellRenderer: memo((params: CustomCellRendererProps) =>
                    <div css={css`
                    margin: auto !important;
                `}>
                        <LogStatus {...params} />
                    </div>
                ),
                valueGetter: (params) => params.data?.action || '',
                maxWidth: 95
            },
            {
                field: "appName",
                headerName: "CONNECTOR NAME",
                maxWidth: isTextHidden ? undefined : 200,
                flex: isTextHidden ? 1 : 0,
            },
            {
                field: "genAiApplication",
                headerName: "GENAI APPLICATION",
                maxWidth: isTextHidden ? undefined : 400,
                flex: isTextHidden ? 1 : 0,
                cellRenderer: memo(GenAiApplication)
            },
            {
                field: "user",
                headerName: "USER",
                maxWidth: isTextHidden ? undefined : 350,
                minWidth: 250,
                flex: isTextHidden ? 1 : 0,
            },
            {
                field: "violations",
                headerName: "VIOLATIONS",
                sortable: false,
                cellRenderer: memo(Chips),
                initialHide: true,
                hide: logsAlerts === 'all',
                flex: isTextHidden ? 1 : 0,
            },
            {
                field: "violatingFindings",
                headerName: "VIOLATION FINDINGS",
                sortable: false,
                minWidth: 200,
                valueGetter: (params) => extractEntityTypesToArray(params.data.violatingFindings, params.data.violations),
                cellRenderer: memo(Chips),
                initialHide: true,
                hide: logsAlerts === 'all',
                flex: isTextHidden ? 1 : 0,
            },
            {
                field: "textType",
                headerName: "PROMPT / RESPONSE",
                maxWidth: 105,
                cellRenderer: memo((params: CustomCellRendererProps) =>
                    <div
                        css={
                            css`
                        margin: auto !important;
                        `
                        }>
                        <TextType {...params} />
                    </div>
                ),
            },
            {
                field: "text",
                headerName: "TEXT",
                tooltipField: "text",
                minWidth: 400,
                initialHide: true,
                hide: isTextHidden,
                cellRenderer: memo((params: CustomCellRendererProps) => {
                    return <Text tooltipDisableInteractive tooltipMaxCharacters={200} tooltipMaxWidth={400} ellipsis>{params.valueFormatted ?? params.value}</Text>
                }),
                flex: 1
            },
            {
                field: "actions",
                headerName: "",
                maxWidth: 140,
                sortable: false,
                resizable: false,
                pinned: 'right',
                cellRenderer: memo((params: CustomCellRendererProps & { showViewText: boolean }) => (
                    <div css={
                        css`
                            display: flex;
                            margin: auto !important;
                            gap: 5px;
                        `
                    }>
                        <PSIconButton variant='circle' variantType='secondary' onClick={() => setRowIndexTrueClassificationModal(params.node.rowIndex)} iconName='PSThumbUpIcon' iconSize={20} tooltipLabel={`True ${params.data.isValid ? 'log' : 'alert'} classification`} />
                        <PSIconButton variant='circle' variantType='secondary' onClick={() => setRowIndexFalseClassificationModal(params.node.rowIndex)} iconName='PSThumbDownIcon' iconSize={20} tooltipLabel={`False ${params.data.isValid ? 'log' : 'alert'} classification`} />
                        <PSIconButton variant='circle' variantType='secondary' onClick={() => copyLinkToClipboard(params.data.id)} iconName='PSCopyIcon' iconSize={20} tooltipLabel={`Copy ${params.data.isValid ? 'log' : 'alert'} link`} />
                    </div >
                )),
                cellRendererParams: {
                    showViewText: isTextHidden,
                }
            },
        ]),
        [isLoading, isTextHidden, logsAlerts, rowIndex, textCacheMap, sortBy, sortDirection]);


    useEffect(() => {
        if (isLoading) {
            setRowData(createPlaceholderData(20));
            return;
        }

        if (rowsData) {
            setRowData(rowsData);
        }
    }, [rowsData, isLoading])

    const gridApiRef = React.useRef<AgGridReact>(null);

    const [selectedRows, setSelectedRows] = useState<Array<Log>>([]);

    const handleSelectionChanged = (event: SelectionChangedEvent<Log>) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map((node: any) => node.data);
        setSelectedRows(selectedData);
    }

    useEffect(() => {
        if (!gridApiRef.current) return;
        if (!isError) return;
        setRowData([]);
        gridApiRef?.current?.api?.showNoRowsOverlay();
    }, [gridApiRef, isError])


    useEffect(() => {
        gridApiRef.current?.api?.sizeColumnsToFit();
    }, [isLoading, isTextHidden, logsAlerts, rowIndex, textCacheMap, sortBy, sortDirection])

    const fetchReportTrueClassification = async () => {

        const body: TClassificationBody = {
            classification: `True-${rowData[rowIndexTrueClassificationModal!]?.isValid ? 'positive' : 'negative'}`,
            classificationType: null,
            flipClassification: false,
            textType: rowData[rowIndexTrueClassificationModal!]?.textType!.toLowerCase() as TTextType,
            promptResponseId: rowData[rowIndexTrueClassificationModal!]?.promptResponseId!,
        };

        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        const response = await fetch('/api/be/report-classification', request);
        if (!response.ok) {
            throw new Error('Error reporting classification.');
        }

        toast.success(`The classification was successfully reported.`);
        refetchTableData();
    }


    return (
        <>
            <Grid
                colDefs={colDefs}
                setSortBy={setSortBy}
                setSortDirection={setSortDirection}
                handleSelectionChanged={handleSelectionChanged}
                selectedRows={selectedRows}
                setItemsPerPage={setItemsPerPage}
                itemsPerPage={itemsPerPage}
                pagination={pagination}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isError={isError}
                data={rowsData}
                SelectedRowIdx={rowIndex}
                isLoading={isLoading}
                setRowIndex={setRowIndex}
            />

            <PSDialog
                title={`True ${rowData[rowIndexTrueClassificationModal!]?.isValid ? 'Log' : 'Alert'} Classification`}
                open={isTrueClassificationModalOpen}
                onClose={() => setRowIndexTrueClassificationModal(null)}
                action={() => fetchReportTrueClassification()}
            >
                <Text color='black'>Are you sure you want to report true detection of this {rowData[rowIndexTrueClassificationModal!]?.isValid ? 'log' : 'alert'}?</Text>
            </PSDialog>
            <PSDialog
                title={`False ${rowData[rowIndexFalseClassificationModal!]?.isValid ? 'Log' : 'Alert'} Classification`}
                open={isFalseClassificationModalOpen}
                onClose={() => setRowIndexFalseClassificationModal(null)}
                action={submitFalseClassificationForm}
                isActionDisabled={!isFalseClassificationFormValid}
            >
                <FalseClassification
                    promptResponseId={rowData[rowIndexFalseClassificationModal!]?.promptResponseId!}
                    appName={rowData[rowIndexFalseClassificationModal!]?.genAiApplication?.domain || rowData[rowIndexFalseClassificationModal!]?.appName!}
                    textType={rowData[rowIndexFalseClassificationModal!]?.textType!.toLowerCase() as TTextType}
                    applicationType={rowData[rowIndexFalseClassificationModal!]?.applicationType!}
                    isValid={rowData[rowIndexFalseClassificationModal!]?.isValid!}
                    onFormStateChange={handleFalseClassificationFormStateChange}
                    setSubmitForm={setSubmitFalseClassificationForm}
                    refetchTableData={refetchTableData}
                />
            </PSDialog>
            <PSDialog
                title={`Request to View ${rowData[rowIndexViewTextRequestModal!]?.textType}`}
                actionButtonText='Send'
                open={isViewTextRequestModalOpen}
                onClose={() => setRowIndexViewTextRequestModal(null)}
                action={submitViewTextRequestForm}
                isActionDisabled={!isViewTextRequestFormValid}
            >
                <ViewHiddenText
                    onFormStateChange={handleViewTextRequestFormStateChange}
                    setSubmitForm={setSubmitViewTextRequestForm}
                    promptResponseId={rowData[rowIndexViewTextRequestModal!]?.promptResponseId!}
                    textType={rowData[rowIndexViewTextRequestModal!]?.textType!.toLowerCase() as TTextType}
                    setTextCacheMap={setTextCacheMap}
                    rowIndex={rowIndexViewTextRequestModal!}
                />
            </PSDialog>
        </>
    )

}

export default AlertsLogGrid;