/** @jsxImportSource @emotion/react */
import React, {FC} from 'react';
import {AgGridReact, CustomCellRendererProps} from "ag-grid-react";
import { ColDef, SizeColumnsToFitGridStrategy, SizeColumnsToFitProvidedWidthStrategy, SizeColumnsToContentStrategy, SortChangedEvent, RowClickedEvent, SelectionChangedEvent } from 'ag-grid-community';

import {css} from "@emotion/react";
import {PSPagination, NoData, PSError, Text} from "../../index";
import {Skeleton} from "@mui/material";
import {Log, Pagination, SortByFields, SortDirection} from "../../../gql/generated/graphql";
import {createPlaceholderData} from "../../../utils";

interface GridProps {
    colDefs: Array<ColDef>;
    handleSelectionChanged: (event: SelectionChangedEvent) => void;
    SelectedRowIdx: number | null;
    isError: boolean | undefined;
    isLoading: boolean | undefined;
    data: any[] | undefined;
    selectedRows: any[];
    pagination: Pagination | undefined;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setItemsPerPage: React.Dispatch<React.SetStateAction<number>>;
    itemsPerPage: number;
    setSortBy: React.Dispatch<React.SetStateAction<SortByFields>>;
    setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>;
    setRowIndex: React.Dispatch<React.SetStateAction<number | null>>;

}

export const Grid: FC<GridProps> = (props) => {
    const {
        colDefs,
        SelectedRowIdx,
        data,
        handleSelectionChanged,
        setCurrentPage,
        isError,
        pagination,
        setSortBy,
        setSortDirection,
        isLoading,
        setRowIndex,
    } = props;

    const gridApiRef = React.useRef<AgGridReact>(null);

    const defaultColDef: ColDef = React.useMemo(() => {
        return {
            sortable: false,
            filter: false,
            resizable: false,
            rowDrag: false,
            suppressMovable: true,
            minWidth: 150,
            cellRenderer: (params: CustomCellRendererProps) => {
                if (params.data?.isError) return <PSError direction='row' message='row load failed'/>
                return <Text ellipsis>{params.valueFormatted ?? params.value}</Text>
            },
            cellRendererSelector: (params) => {
                if (params.data.id.startsWith('placeholder') || isLoading) {
                    return {
                        component: () => <Skeleton variant='text' animation='pulse' height={20} width={'100%'}
                                                   className='loading-skeleton'/>
                    }
                }
            },
            cellStyle: (params) => {
                if (params.rowIndex === SelectedRowIdx) {
                    return {background: 'var(--color-black-10)'}
                } else {
                    return {background: 'transparent'}
                }
            }
        }
    }, [SelectedRowIdx, isLoading])

    const autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
        type: 'fitGridWidth',
        columnLimits: [
            {
                colId: 'select',
                maxWidth: 65,
                minWidth: 65
            }
        ],
    }

    const placeholderData = createPlaceholderData(20)

    const handleSortChanged = (event: SortChangedEvent<Log>) => {
        const columnsChanged = event.columns;

        if (!columnsChanged) return;
        if (columnsChanged.length === 0) return;

        const columnToSort = columnsChanged[0];

        const sortDirection = columnToSort.getSort();
        const sortBy = columnToSort.getColDef().enumFieldSort as SortByFields;

        const SortDirectionMap = {
            'asc': SortDirection.Ascending,
            'desc': SortDirection.Descending
        }

        setSortBy(sortBy);
        setSortDirection(SortDirectionMap[sortDirection || 'desc']);
        setCurrentPage(1);
    }

    const handleRowClick = (event: RowClickedEvent) => {
        if (isLoading) return;
        if ((event?.event?.target as HTMLElement).closest('[col-id="actions"]')) return;
        if ((event?.event?.target as HTMLElement).closest('[col-id="select"]')) return;
        if (event.data?.isError) return;
        setRowIndex(event.rowIndex);
    }


        return (
            <div className="ag-theme-quartz" style={{flexGrow: 1}}>
                <AgGridReact
                    rowHeight={50}
                    ref={gridApiRef}
                    css={css`--ag-wrapper-border-radius: 15px 15px 0 0; height: calc(100% - 50px) !important;`}
                    className="clickable-rows"
                    rowSelection='multiple'
                    autoSizeStrategy={autoSizeStrategy}
                    onSortChanged={handleSortChanged}
                    onRowClicked={handleRowClick}
                    defaultColDef={defaultColDef}
                    rowData={data || placeholderData}
                    columnDefs={colDefs}
                    animateRows={false}
                    tooltipTrigger={undefined}
                    tooltipShowDelay={10000000000}
                    onSelectionChanged={handleSelectionChanged}
                    noRowsOverlayComponent={() => {
                        if (isError) {
                            return <PSError/>
                        }
                        return <NoData message='Try expanding your selected date range and removing any filters.'/>
                    }}
                    suppressRowClickSelection
                    suppressCellFocus
                />
                <PSPagination
                    currentPage={pagination?.currentPage ?? 1}
                    totalItems={pagination?.totalCount ?? 0}
                    itemsPerPage={pagination?.itemsPerPage ?? 50}
                    onPageChange={setCurrentPage}
                    isLoading={isLoading}
                />
            </div>
        )
}

