import { DateTime } from 'luxon';
import React, { createContext, useCallback, useEffect } from 'react';
import { useLocalStorage, useSearchParam } from 'react-use';
import { predefinedToDates } from '../ui-kit';

export type TDateSelection = (TAbsoluteDateSelection | TRelativeDateSelection) & {
    lastResetTimestamp: number | null;
};

export const dateStoreDefault = {
    logs: {
        resetAfterMinutes: 60 * 24 * 7,
        dates: predefinedToDates('last-month'),
        period: 'last-month',
        type: 'relative',
        lastResetTimestamp: null,
    },
    audit: {
        resetAfterMinutes: 60 * 24 * 7,
        dates: predefinedToDates('last-month'),
        period: 'last-month',
        type: 'relative',
        lastResetTimestamp: null,
    },
    dashboard: {
        resetAfterMinutes: 60 * 24 * 7,
        dates: predefinedToDates('last7days'),
        period: 'last7days',
        type: 'relative',
        lastResetTimestamp: null,
    },
} as const satisfies Record<string, { resetAfterMinutes: number } & TDateSelection>;

export type TPredefinedPageDate = keyof typeof dateStoreDefault;

export const DateTypeSelection = {
    absolute: "absolute",
    relative: "relative",
} as const;

export type TDateTypeSelection<T extends keyof typeof DateTypeSelection> =
    (typeof DateTypeSelection)[T];

export type TAbsoluteDateSelection = {
    type: TDateTypeSelection<"absolute">;
    period: 'custom';
    dates: [Date, Date];
};

export type TRelativeDateSelection = {
    type: TDateTypeSelection<"relative">;
    period: TPredefinedDateOrTimeSelection;
    dates: [Date, Date];
};

export const predefinedDateSelections = ['today', 'yesterday', 'last7days', 'last-month', 'last-year'] as const;
export const predefinedTimeSelections = ['last5minutes', 'last15minutes', 'last30minutes', 'last1hour', 'last3hours', 'last6hours', 'last12hours'] as const;
export const predefinedDateOrTimeSelections = [...predefinedDateSelections, ...predefinedTimeSelections] as const;

export type TPredefinedDateSelection = typeof predefinedDateSelections[number];
export type TPredefinedTimeSelection = typeof predefinedTimeSelections[number];
export type TPredefinedDateOrTimeSelection = TPredefinedDateSelection | TPredefinedTimeSelection;

export type DateContextType = {
    date: TDateSelection;
    setDate: React.Dispatch<React.SetStateAction<TDateSelection>>;
};

export const DateContext = createContext<DateContextType | null>(null);

type IProps = {
    children: React.ReactNode;
    page: TPredefinedPageDate;
};

export const DateContextProvider: React.FC<IProps> = ({ children, page }) => {
    const searchParamsDateType = useSearchParam('dateType') as keyof typeof DateTypeSelection;
    const searchParamsDates = useSearchParam('dates');
    const searchParamsPeriod = useSearchParam('period') as TPredefinedDateOrTimeSelection;

    const getDateSelection = useCallback(
        (dateType: keyof typeof DateTypeSelection): TDateSelection => {
            if (dateType === 'absolute') {
                return {
                    type: 'absolute',
                    period: 'custom',
                    dates: searchParamsDates
                        ? (searchParamsDates.split(',').map((x) => new Date(x)) as [Date, Date])
                        : dateStoreDefault[page].dates,
                    lastResetTimestamp: null,
                };
            }

            return {
                type: 'relative',
                period: predefinedDateOrTimeSelections.includes(searchParamsPeriod)
                    ? searchParamsPeriod
                    : 'last7days',
                dates: predefinedToDates(searchParamsPeriod),
                lastResetTimestamp: null,
            };
        },
        [searchParamsDates, searchParamsPeriod]
    );

    const [storedDateType, setStoredDateType] = useLocalStorage<TDateSelection>(
        `dateStore_${page}`,
        getDateSelection(searchParamsDateType),
        {
            raw: false,
            serializer: (value) => {
                const base = {
                    lastResetTimestamp: value.lastResetTimestamp,
                };

                if (value.type === 'absolute') {
                    return JSON.stringify({
                        ...base,
                        type: 'absolute',
                        period: 'custom',
                        dates: [value.dates[0].toISOString(), value.dates[1].toISOString()],
                    });
                }

                return JSON.stringify({
                    ...base,
                    type: 'relative',
                    period: value.period,
                    dates: predefinedToDates(value.period).map((x) => x.toISOString()),
                });
            },
            deserializer: (value) => {
                const parsed = JSON.parse(value);
                if (parsed.type === 'absolute') {
                    const from = DateTime.fromISO(parsed.dates[0]);
                    const to = DateTime.fromISO(parsed.dates[1]);

                    return {
                        type: 'absolute',
                        period: 'custom',
                        dates: [from.toJSDate(), to.toJSDate()],
                        lastResetTimestamp: parsed.lastResetTimestamp,
                    };
                }

                return {
                    type: 'relative',
                    period: parsed.period,
                    dates: predefinedToDates(parsed.period),
                    lastResetTimestamp: parsed.lastResetTimestamp,
                };
            },
        }
    );

    useEffect(() => {
        if (!storedDateType?.lastResetTimestamp) return;

        const resetAfterMinutes = dateStoreDefault[page].resetAfterMinutes;
        const lastReset = DateTime.fromMillis(storedDateType.lastResetTimestamp);
        const minutesSinceReset = DateTime.now().diff(lastReset, 'minutes').minutes;

        if (minutesSinceReset >= resetAfterMinutes) {
            setStoredDateType({
                dates: dateStoreDefault[page].dates,
                period: dateStoreDefault[page].period,
                type: dateStoreDefault[page].type,
                lastResetTimestamp: DateTime.now().toMillis()
            });
        }
    }, [page, storedDateType?.lastResetTimestamp, setStoredDateType]);

    return (
        <DateContext.Provider value={{ date: storedDateType!, setDate: setStoredDateType as React.Dispatch<React.SetStateAction<TDateSelection>> }}>
            {children}
        </DateContext.Provider>
    );
};

export const useDateContext = () => {
    const context = React.useContext(DateContext);
    if (!context) {
        throw new Error('useDateContext must be used within a DateContextProvider');
    }

    return context;
};
