/** @jsxImportSource @emotion/react */
import React from 'react';
import { FormControl, InputLabel, Select, SelectChangeEvent, Switch } from '@mui/material';
import Chip from '../../Chip/Chip';
import { DropdownSelectStyle } from './DropdownSelect.css';
import PSMenuItem from '../../PSMenuItem/PSMenuItem';
import Text from '../../Text/Text';
import Icon from '../../Icon/Icon';

type TOption<TValue> = {
    value: TValue;
    label: any;
};

type IProps<TValue> = {
    label?: string;
    options: Array<TOption<TValue>>;
    multiple?: boolean;
    minWidth?: number;
    value?: TValue | TValue[];
    onChange?: (newValue: TValue | TValue[]) => void;
    showExclude?: boolean;
    onExcludeChange?: (exclude: boolean) => void;
    isExcludeMode?: boolean;
}

const DropdownSelect: React.FC<IProps<any>> = <TValue,>(props: IProps<TValue>) => {
    const { options, label, multiple = false, onChange, value = multiple ? [] : '', minWidth = 120, showExclude, isExcludeMode, onExcludeChange } = props;

    const [selectedValue, setSelectedValue] = React.useState<TValue | TValue[] | "">(value);
    const [isExcludeChecked, setIsExcludeChecked] = React.useState<boolean>(isExcludeMode || false);

    const onValueChange = (event: SelectChangeEvent<TValue | TValue[]>) => {
        let newValue = event.target.value;

        const filteredExistingValue =
            Array.isArray(newValue) ?
                newValue.filter((value) => options.some((option) => option.value === value))
                : options.some((option) => option.value === newValue)
                    ? newValue
                    : '';

        if (!multiple) {
            setSelectedValue(filteredExistingValue as TValue | "");
            onChange && onChange(filteredExistingValue as TValue);
            return;
        }

        setSelectedValue(filteredExistingValue as TValue | TValue[] | "");
    }

    const onClose = () => {
        if (!multiple) return;
        if (!onChange) return;

        onChange(selectedValue as unknown as TValue | TValue[]);
        handleExcludeChange(isExcludeChecked);
    }

    const isSelected = (givenValue: TValue) => {
        if (Array.isArray(selectedValue)) {
            if (selectedValue.length === 0) return false;
            return selectedValue.includes(givenValue);
        }
        return givenValue === selectedValue;
    }

    const handleExcludeChange = (exclude: boolean) => {
        if (onExcludeChange) {
            onExcludeChange(exclude);
        }
    };

    return (
        <FormControl sx={{ margin: '8px 0', minWidth }} size="small">
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                multiple={multiple}
                value={selectedValue}
                onClose={onClose}
                onChange={onValueChange}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    slotProps: {
                        paper: {
                            style: {
                                backgroundColor: 'var(--color-white)',
                                borderRadius: '10px',
                                boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.15)',
                                maxHeight: '500px',
                            },
                            sx: {
                                '& .MuiList-root': {
                                    padding: '0',
                                }
                            }
                        },
                    }
                }}
                renderValue={(value) => {
                    const values = Array.isArray(value) ? value : [value];

                    if (!multiple) {
                        return <Text variant="bold">{options.find((option) => option.value === value)?.label || value as React.ReactNode}</Text>
                    }
                    return <div css={DropdownSelectStyle.valuesContainer}>
                        {values.map((value) => {
                            return <Chip
                                variant='outlined'
                                key={value as string}
                                size='small'
                                icon={isExcludeChecked ? <Icon iconName='RemoveCircleTwoTone' iconSize={'small'} color="black-70" /> : undefined}
                                label={options.find((option) => option.value === value)?.label || value as React.ReactNode}
                            />
                        })}
                    </div>
                }}
            >
                {showExclude && <div style={{ display: 'flex', gap: 5, justifyContent: 'flex-end', alignItems: 'center', padding: 5, zIndex: 1, position: 'sticky', top: 0, background: 'var(--color-white)' }}>
                    <Text variant='small'>Exclude</Text>
                    <Switch checked={isExcludeChecked} onChange={(_, checked) => setIsExcludeChecked(checked)} defaultChecked size="small" />
                </div>}
                {options.map((option) => {
                    return <PSMenuItem
                        type={multiple ? 'checkbox' : 'icon'}
                        selected={isSelected(option.value)}
                        key={option.value as string}
                        value={option.value as string}>{option.label}
                    </PSMenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default DropdownSelect;