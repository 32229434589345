import { Link, Outlet, useLocation } from 'react-router-dom';
import {PSTabs, PSTab} from "../../ui-kit";
import React from "react";

const TABS = {
    'employees': 'Employees',
    'developers': 'Developers',
    'homegrown-apps': 'Homegrown apps'
};

const DashboardsPage = () => {
    const { pathname } = useLocation();

    const tabValue = pathname.split('/').pop();
    return(
        <>
            <PSTabs
                value={tabValue}
                indicatorColor="secondary"
                scrollButtons="auto"
            >
                {Object.entries(TABS).map(([key, value]) => (
                    <PSTab
                        key={key}
                        value={key}
                        label={value}
                        component={Link}
                        to={key}
                    />
                ))}
            </PSTabs>
            <Outlet/>
        </>
    )
}

export default DashboardsPage;