/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Divider, ListItemIcon, ListItemText, MenuItem, SelectChangeEvent } from '@mui/material';
import Popover from '../ui-kit/Popover/Popover';
import PSSelect from '../ui-kit/PSSelect/PSSelect';
import { Tooltip, Text, Icon } from '../ui-kit';
import { NavigationMenuUserStyle } from './NavigationMenuUser.css';
import { useAuthUser } from '@frontegg/react';
import { useNavigate, useLocation } from 'react-router-dom';


import { useQuery } from '@tanstack/react-query';
import { logout, removeLoginState } from '../utils/fronteggAuth';
import { setCurrentTenant, getCurrentTenant, getEnvTenants, TTenantDetails } from '../routes/ManageTenants/tenants';
import clsx from 'clsx';

type IProps = {
    isCollapsed: boolean;
};

const NavigationMenuUser: React.FC<IProps> = (props) => {
    const { isCollapsed } = props;

    const user = useAuthUser();
    const [userName, setUserName] = useState('');
    const [userButtonAnchorEl, setUserButtonAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const selectedUserTenant = getCurrentTenant();
    const navigate = useNavigate();
    const location = useLocation();

    const { data: userTenantsData = [] } = useQuery(
        {
            queryKey: ["userTenants"],
            queryFn: async () => {
                const tenants: TTenantDetails[] = await getEnvTenants(user);
                return tenants.sort((a, b) => a.id.localeCompare(b.id));
            },
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false
        }
    );

    const isUserMenuOpen = Boolean(userButtonAnchorEl);

    useEffect(() => {
        if (user.name) {
            setUserName(user.name.split(' ')[0]);
        } else if (user.email) {
            setUserName(user.email.split("@", 2)[0])
        } else {
            setUserName("Profile");
        }
    }, [user])


    const openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUserButtonAnchorEl(event.currentTarget);
    };

    const closeUserMenu = () => {
        setUserButtonAnchorEl(null);
    };

    const handleTenantSelection = async (event: SelectChangeEvent<String>) => {
        const tenant: TTenantDetails = userTenantsData.find(t => t.id === event.target.value) as TTenantDetails;
        if (selectedUserTenant?.name !== tenant.name) {
            await fetch('/api/access/register-logout');

            setCurrentTenant(tenant);
            
            if (location.pathname === "/") {
                window.location.reload();
            } else {
                navigate('/');
            }
            await fetch('/api/access/register-login');
        }
    };

    const logOutUser = useCallback(() => {
        fetch('/kibana/auth/logout', {
            method: 'POST',
            headers: {
                'osd-version': '2.11.0',
                'osd-xsrf': 'osd-fetch',
                'Content-Type': 'application/json'
            }
        })
            .catch((error) => {
                console.error('Error logging out from Kibana:', error);
            })
            .finally(() => {
                logout(true);
            });
    }, [logout]);

    return (
        <React.Fragment>
            <Tooltip title={isCollapsed ? userName : ''} placement='right'>
                <button onClick={openUserMenu} css={NavigationMenuUserStyle.userButton(isCollapsed)} className={clsx({ 'active': isUserMenuOpen })}>
                    {!isCollapsed ? <div className='user-details'>
                        <div className='user-details-name'>
                            <Icon color='white' iconSize={15} iconName='PSUserCircleMenuIcon' />
                            <Text color='white'>{userName}</Text>
                        </div>
                        <div className='user-details-tenant'>
                            <Text color='black-25'>{selectedUserTenant?.name}</Text>
                        </div>
                    </div> :
                        <Icon color='white' iconSize={15} iconName='PSUserCircleMenuIcon' css={NavigationMenuUserStyle.userDetailsIcon} />}
                    <Icon color='white' iconSize={15} iconName={isUserMenuOpen ? 'PSChevronUpIcon' : 'PSChevronDownIcon'} css={NavigationMenuUserStyle.userDetailsIcon} />
                </button>
            </Tooltip>
            <Popover width={220} css={NavigationMenuUserStyle.userMenuPopover} open={isUserMenuOpen} anchorEl={userButtonAnchorEl} onClose={closeUserMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div css={NavigationMenuUserStyle.userDetails}>
                    <Icon iconSize={40} iconName='PSUserCircleIcon' />
                    <Text variant='bold'>{userName}</Text>
                    {user.email && <Text ellipsis variant='small' color='black-70'>{user.email}</Text>}
                </div>
                <Divider />
                {userTenantsData.length > 1 && <>
                    <div css={NavigationMenuUserStyle.userTenants}>
                        <Text variant='small'>Account</Text>
                        <PSSelect
                            onChange={(event) => handleTenantSelection(event as SelectChangeEvent<String>)}
                            value={selectedUserTenant?.id}
                            options={userTenantsData.map(tenant => ({
                                id: tenant.id,
                                value: tenant.id,
                                label: tenant.name
                            }))} />
                    </div>
                    <Divider />
                </>}
                <MenuItem css={NavigationMenuUserStyle.logOutButton} onClick={logOutUser}>
                    <ListItemIcon>
                        <Icon iconSize={15} color='black-70' iconName='PSLogoutIcon' />
                    </ListItemIcon>
                    <ListItemText>
                        <Text color='black-70'>Logout</Text>
                    </ListItemText>
                </MenuItem>
            </Popover>
        </React.Fragment>
    )
}

export default NavigationMenuUser;